import { resguard } from 'resguard'
import type { ApiKey } from 'tela-api/src/database/schemas'
import type { DataExtractionConfig } from '~/pages/any2table/app/index.vue'
import type { Tela } from '~/types/tela'

export interface ExtractionColumn {
    title: string
    description: string
    type: string
}

export interface ExtractionFile {
    file: File
    id: string
    url: string
    status: 'loading' | 'success' | 'error'
}

export interface ExtractionRow {
    fileId: string
    data: Record<string, unknown>[]
}

function useExtraction() {
    const log = useLog()
    const api = useNuxtAPI()
    const route = useRoute()
    const { public: { any2table: { canvasId, uploadApi } } } = useRuntimeConfig()

    const abortControllers = useState<Record<string, AbortController>>('data-extraction-abort-controllers', () => ({}))
    const importedColumns = useState<boolean>('data-extraction-imported-columns', () => false)
    const promptId = computed(() => route.params.promptid as string ?? canvasId)

    const appConfig = inject<Tela.App.State<DataExtractionConfig>['config']>('appConfigKey', {} as DataExtractionConfig)
    const documentVariable = computed<string>(() => appConfig?.variable ?? 'document')

    const columns = useSyncedState<ExtractionColumn[]>('data-extraction-columns', [{
        title: '',
        description: '',
        type: 'text',
    }], { dependsOn: [promptId] })
    const singleCellColumns = useState<ExtractionColumn[]>('data-extraction-array-properties', () => [])

    const files = useState<ExtractionFile[]>('data-extraction-files', () => [])
    const rows = useState<ExtractionRow[]>('data-extraction-rows', () => [])
    const singleCellRows = useState<ExtractionRow[]>('data-single-cell-rows', () => [])

    const hasInternalPrompt = computed(() => route.params.promptid && route.name === 'app-prompt-promptid-data-extraction')

    function addColumn() {
        columns.value.push({
            title: '',
            description: '',
            type: 'text',
        })
    }

    // reliving old implementation that was affecting clients
    function saveSingleCellData(addedFile: any, extractionResult: any) {
        function flatObject(obj: any, parentKey = '', result: any = {}) {
            for (const key in obj) {
                const newKey = parentKey ? `${parentKey}.${key}` : key
                if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                    flatObject(obj[key], newKey, result)
                }
                else if (Array.isArray(obj[key])) {
                    result[newKey] = JSON.stringify(obj[key])
                }
                else {
                    result[newKey] = obj[key]
                }
            }
            return result
        }

        const flattenedData = flatObject(extractionResult)

        singleCellRows.value.push({
            fileId: addedFile.id,
            data: [flattenedData],
        })
    }

    function updateColumn(idx: number, updates: Partial<ExtractionColumn>) {
        columns.value[idx] = { ...columns.value[idx], ...updates }
        files.value = []
        rows.value = []
    }

    function removeColumn(index: number) {
        if (columns.value.length === 1) {
            columns.value = [{
                title: '',
                description: '',
                type: 'text',
            }]
        }
        else {
            columns.value.splice(index, 1)
        }
    }

    async function addFiles(list: File[] | FileList) {
        const newFiles = [...list].map((file: File) => ({
            file,
            id: `${file.name}-${file.size}-${file.lastModified}-${file.type}`,
            url: URL.createObjectURL(file),
            status: 'loading' as const,
        }))

        const filesToUpload: ExtractionFile[] = []

        for (const addedFile of newFiles) {
            if (files.value.some(({ id }) => id === addedFile.id)) {
                useNotification().warning(
                    'File already exists',
                    `${addedFile.file.name} is already in the list.`,
                    {
                        timeout: 5000,
                    },
                )
                continue
            }

            filesToUpload.push(addedFile)
        }

        files.value = files.value.concat(...filesToUpload)
        for (const addedFile of filesToUpload)
            abortControllers.value[addedFile.id] = new AbortController()

        const chunkSize = 5
        const filesToUploadChunks = Array.from({ length: Math.ceil(filesToUpload.length / chunkSize) }, (_, i) =>
            filesToUpload.slice(i * chunkSize, (i + 1) * chunkSize))

        const { createApiKey, deleteApiKey } = useApiKey()

        let apiKey: ApiKey | null = null
        if (hasInternalPrompt.value) {
            apiKey = (await createApiKey('ephemeral-key')) as ApiKey
        }

        for await (const filesToUploadChunk of filesToUploadChunks) {
        /* eslint no-async-promise-executor: "off" */
            await Promise.all(filesToUploadChunk.map(addedFile => new Promise<void>(async (res) => {
                if (!abortControllers.value[addedFile.id] || abortControllers.value[addedFile.id]?.signal.aborted) {
                // File upload was aborted
                    return res()
                }
                const uploadResult = await resguard(uploadFile(addedFile.id, addedFile.file))

                if (uploadResult.error) {
                    log.error('Error uploading file', { error: uploadResult.error })
                    updateFile(addedFile.id, { status: 'error' })
                    useNotification().error(
                        'Ops!',
                        `Error: ${uploadResult.error.message}. If the error persists, please contact us.`,
                        {
                            timeout: 5000,
                        },
                    )
                    return res()
                }

                if (uploadResult.data && 'url' in uploadResult.data)
                    addedFile.url = uploadResult.data.url

                log.info('New file added', { file: addedFile })

                const extractionResult = await resguard(extractData(addedFile, apiKey!))
                if (extractionResult.error) {
                    useNotification().error(
                        'Ops!',
                        `Error: ${extractionResult.error.message}. If the error persists, please contact us.`,
                        {
                            timeout: 5000,
                        },
                    )
                    updateFile(addedFile.id, { status: 'error' })
                    return res()
                }

                if (!extractionResult.data) {
                    updateFile(addedFile.id, { status: 'error' })
                    return res()
                }

                if (importedColumns.value) {
                    saveSingleCellData(addedFile, extractionResult.data)

                    function flattenObject(input: Record<string, any>, parentKey: string = ''): Record<string, any> {
                        const items: Record<string, any> = {}
                        Object.keys(input).forEach((key) => {
                            const newKey = parentKey ? `${parentKey}.${key}` : key
                            const value = input[key]
                            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                                Object.assign(items, flattenObject(value, newKey))
                            }
                            else {
                                items[newKey] = value
                            }
                        })
                        return items
                    }

                    function processObject(obj: Record<string, any>): Record<string, any>[] {
                        const output: Record<string, any>[] = []
                        const baseEntry: Record<string, any> = {}
                        const listEntries: { [key: string]: any[] } = {}

                        Object.entries(obj).forEach(([key, value]) => {
                            if (Array.isArray(value)) {
                                listEntries[key] = value
                            }
                            else {
                                baseEntry[key] = value
                            }
                        })

                        if (Object.keys(listEntries).length === 0) {
                            return [baseEntry]
                        }

                        Object.entries(listEntries).forEach(([listKey, listItems]) => {
                            listItems.forEach((item) => {
                                if (typeof item === 'object' && item !== null) {
                                    const flatItem = flattenObject(item, listKey)
                                    const nestedResults = processObject(flatItem)
                                    nestedResults.forEach((result) => {
                                        output.push({ ...baseEntry, ...result })
                                    })
                                }
                                else {
                                    output.push({ ...baseEntry, [listKey]: item })
                                }
                            })
                        })

                        return output
                    }

                    function objectToRows(input: Record<string, any>): Record<string, any>[] {
                        const flattenedJSON = flattenObject(input)
                        return processObject(flattenedJSON)
                    }

                    const result = objectToRows(extractionResult.data)

                    rows.value.push({
                        fileId: addedFile.id,
                        data: result,
                    })
                }
                else {
                    rows.value.push({
                        fileId: addedFile.id,
                        data: extractionResult.data,
                    })
                }

                updateFile(addedFile.id, { status: 'success' })
                res()
            })))
        }

        await deleteApiKey(apiKey!.id)
    }

    function updateFile(id: string, updates: Partial<ExtractionFile>) {
        const idx = files.value.findIndex(file => file.id === id)
        if (idx === -1)
            return

        files.value[idx] = { ...files.value[idx], ...updates }
    }

    function removeFile(file: ExtractionFile) {
        const index = files.value.findIndex(({ id }) => id === file.id)
        if (index < 0)
            return

        abortControllers.value[file.id].abort()
        delete abortControllers.value[file.id]
        files.value.splice(index, 1)
        rows.value = rows.value.filter(({ fileId }) => fileId !== file.id)
    }

    async function extractData(file: ExtractionFile, apiKey?: ApiKey) {
        try {
            const resolvedCanvasId = hasInternalPrompt.value ? route.params.promptid : canvasId
            log.info('Extracting data from file', {
                url: file.url,
                resolvedCanvasId,
                hasInternalPrompt: hasInternalPrompt.value,
                appConfig: { ...appConfig },
            })

            async function toBase64(file: File) {
                return new Promise<string>((resolve, reject) => {
                    const reader = new FileReader()
                    reader.onload = () => resolve(reader.result as string)
                    reader.onerror = reject
                    reader.readAsDataURL(file)
                })
            }

            const requestBody = {
                useEnvironmentApi: hasInternalPrompt.value,
                uses: resolvedCanvasId,
                with: {
                    [documentVariable.value]: {
                        file_url: file.url.startsWith('blob:') ? await toBase64(file.file) : file.url,
                    },
                },
                ...!importedColumns.value
                    ? {
                            override: {
                                structuredOutput: {
                                    enabled: true,
                                    schema: {
                                        title: 'defaultOutput',
                                        description: 'Output structure thats always used on the response',
                                        type: 'object',
                                        required: [],
                                        properties: {
                                            data: {
                                                type: 'array',
                                                description: 'the extracted data from the document',
                                                items: {
                                                    type: 'object',
                                                    required: [],
                                                    properties: columns.value.reduce((acc, col) => {
                                                        acc[col.title] = {
                                                            type: col.type === 'text' ? 'string' : col.type,
                                                            description: col.description,
                                                        }
                                                        return acc
                                                    }, {} as Record<string, unknown>),
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        }
                    : undefined,
            }

            let data: any
            if (hasInternalPrompt.value) {
                const { public: { proxyUrl } } = useRuntimeConfig()

                const response = await fetch(`${proxyUrl}/v1/chat/completions`, {
                    method: 'POST',
                    signal: abortControllers.value[file.id].signal,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey!.token}`,
                    },
                    body: JSON.stringify(requestBody),
                })

                const jsonResponse = await response.json()
                if (response.status !== 200) {
                    const error = new Error(jsonResponse.message ?? 'Unknown error')
                    Object.assign(error, { status: response.status })
                    throw error
                }

                data = jsonResponse
            }
            else {
                data = await api('/api/any2table/chat/completions', {
                    method: 'POST',
                    signal: abortControllers.value[file.id].signal,
                    body: requestBody,
                })
            }

            log.info('Extraction result', { data })

            const content = data.choices[0].message.content
            if (!content || (!content.data && !importedColumns.value))
                throw new Error('Invalid response')

            return importedColumns.value ? content : content.data
        }
        catch (e) {
            if (e instanceof Error && (
                ('status' in e && e.status === 404)
                || ('response' in e && e.response instanceof Response && e.response.status === 404))
            ) {
                useNotification().warning('Canvas has no promoted version', 'Please promote a version to extract data.', {
                    timeout: 5000,
                })
                return
            }

            if (e instanceof Error && (e.name === 'AbortError' || ('cause' in e && (e.cause as any).name === 'AbortError'))) {
                log.warn('Extraction aborted', { file: file.file.name })
                return
            }

            if (e instanceof Error && 'data' in e && (e.data as any).message) {
                useNotification().error('Error extracting data', (e.data as any).message, {
                    timeout: 5000,
                })
                return
            }

            log.error('Error extracting data', { error: e })
            throw e
        }
    }

    async function uploadFile(fileId: string, file: File) {
        async function getToken() {
            const { data: token, error } = await resguard(api('/api/auth/otp/token', {
                method: 'GET',
                signal: abortControllers.value[fileId].signal,
            }))
            if (error) {
                if ('response' in error && (error.response as Response).status === 404)
                    return

                throw error
            }
            return token
        }

        try {
            const formData = new FormData()
            formData.append('file', file)

            const token = await getToken()
            if (!token) {
                log.warn('No token found to upload file')
                return
            }

            const response = await fetch(uploadApi, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${await getToken()}`,
                },
                body: formData,
                signal: abortControllers.value[fileId].signal,
            })

            const data = await response.json()
            if (!('url' in data))
                throw new Error('Invalid response')

            log.info('File uploaded', { response })
            return data as { url: string }
        }
        catch (e) {
            if (e instanceof DOMException && e.name === 'AbortError') {
                log.warn('File upload aborted', { file: file.name, error: e })
                return
            }

            log.error('Error uploading file', { error: e })
            throw e
        }
    }

    function escapeCSV(value: string): string {
        if (!value) {
            return ''
        }

        // Wrap everything in double quotes and escape needed characters
        return `"${value.replace(/"/g, '""').replace(/[\n\r]+/g, ' ')}"`
    }

    async function tableToCSV(opts: {
        singleCell?: boolean,
        includeFileColumn?: boolean,
        separator?: string,
        stringify?: boolean,
    } = { singleCell: false, includeFileColumn: false, separator: ',', stringify: true }) {
        const usedColumns = opts.singleCell ? singleCellColumns.value : columns.value
        const usedRows = opts.singleCell ? singleCellRows.value : rows.value

        const csvColumns = usedColumns.map(({ title }) => title)
        if (opts.includeFileColumn) {
            csvColumns.unshift('filename')
        }

        const lines = usedRows.reduce((acc, row) => {
            let fileName: string

            if (opts.includeFileColumn) {
                const fileData = files.value.find(({ id }) => id === row.fileId)
                fileName = fileData!.file.name
            }

            acc.push(...row.data.map(item => ({
                filename: fileName,
                ...item,
            })))
            return acc
        }, [] as any[])

        const csv = lines.map(row => csvColumns.map((col) => {
            const replacer = (_key: string, value: any) => value === null ? '' : value // specify how you want to handle null values here

            if (opts?.stringify) {
                return JSON.stringify(row[col], replacer)
            }
            else {
                return escapeCSV(String(row[col] ?? ''))
            }
        }).join(opts.separator))

        csv.unshift(csvColumns.map(col => `"${col}"`).join(opts.separator))
        return csv.join('\r\n')
    }

    function resetData() {
        columns.value = []
        files.value = []
        rows.value = []
    }

    return {
        singleCellColumns,
        columns,
        addColumn,
        updateColumn,
        removeColumn,
        files,
        addFiles,
        removeFile,
        rows,
        resetData,
        tableToCSV,
        importedColumns,
    }
}

export { useExtraction }
