import * as devalue from 'devalue'
import { resguard } from 'resguard'

export function useSyncedState<T>(key: string, defaultValue: T, options?: {
    dependsOn?: ComputedRef<string> | Ref<string> | Array<ComputedRef<string> | Ref<string>>
}) {
    let state: Ref<T> = ref(defaultValue) as Ref<T>
    const currentKey = ref(key)
    const db = useIDB({ storeName: 'synced-state', dbName: 'tela-state' })

    if (!areDependenciesMet(options?.dependsOn)) {
        return state
    }

    function resolveKey() {
        if (!options?.dependsOn)
            return key

        const dependencies = Array.isArray(options.dependsOn) ? options.dependsOn : [options.dependsOn]
        const dependenciesKey = dependencies.map(dep => dep.value).join(':') || ''
        return `${key}:${dependenciesKey}`
    }

    function getItem(stateRef: Ref<T>) {
        if (import.meta.server)
            return

        const localStorageValue = window.localStorage.getItem(resolveKey())
        if (localStorageValue) {
            const deserializedValue = deserializeData(localStorageValue)
            if (deserializedValue !== null) {
                stateRef.value = deserializedValue
            }
            window.localStorage.removeItem(resolveKey())
        }

        db.get(resolveKey()).then((value) => {
            if (value !== undefined) {
                stateRef.value = deserializeData(value)
            }
        })
    }

    function setItem(value: T, stateRef: Ref<T>) {
        if (import.meta.server)
            return

        db.set(resolveKey(), serializeData(value))
        stateRef.value = value
    }

    function syncState() {
        if (currentKey.value === resolveKey())
            return

        state = useState(resolveKey(), () => defaultValue)

        if (import.meta.client) {
            getItem(state)

            watchUnique(state, (newVal) => {
                setItem(newVal, state)
            }, { immediate: true, key: resolveKey(), deep: true })
        }

        currentKey.value = resolveKey()
    }

    function deserializeData(value: any) {
        const resolvedValue = resguard(() => devalue.parse(value, {
            VaultFile: ({ url }) => new VaultFile({ url }),
        }))

        if (resolvedValue.error) {
            console.error('Error deserializing data:', resolvedValue.error, 'on key:', resolveKey())
            return handleDeserializationError(value)
        }

        return resolvedValue.data
    }

    function handleDeserializationError(value: any) {
        const parsedValue = resguard(() => JSON.parse(value))
        if (parsedValue.error) {
            console.error('Error parsing data:', parsedValue.error, 'on key:', resolveKey())
            clearStorageItem()
            return defaultValue
        }
        return parsedValue.data
    }

    function clearStorageItem() {
        if (import.meta.client) {
            window.localStorage.removeItem(resolveKey())
            db.remove(resolveKey())
        }
    }

    function serializeData(value: any) {
        const resolvedValue = resguard(() => devalue.stringify(value, {
            VaultFile: entry => entry instanceof VaultFile && { url: entry.url },
        }))

        return resolvedValue.error ? JSON.stringify(value) : resolvedValue.data
    }

    syncState()

    return computed({
        get: () => {
            syncState()
            return state.value
        },
        set: (value) => {
            syncState()
            state.value = value
        },
    })
}

function areDependenciesMet(dependsOn?: ComputedRef<string> | Ref<string> | Array<ComputedRef<string> | Ref<string>>): boolean {
    if (!dependsOn)
        return true
    if (Array.isArray(dependsOn))
        return dependsOn.every(dep => dep && dep.value)
    return !!dependsOn.value
}
