interface IDBOptions {
    dbName?: string
    storeName: string
    version?: number
}

export function useIDB(options: IDBOptions) {
    const { dbName = 'tela', storeName, version = 1 } = options
    let dbInstance: IDBDatabase | null = null

    async function setup(): Promise<IDBDatabase> {
        if (dbInstance)
            return dbInstance

        return new Promise((resolve, reject) => {
            const request = indexedDB.open(dbName, version)
            request.onerror = reject
            request.onsuccess = () => {
                dbInstance = request.result
                // Check if the object store exists
                if (!dbInstance.objectStoreNames.contains(storeName)) {
                    // If it doesn't exist, close the connection and reopen with a new version
                    dbInstance.close()
                    const newVersion = dbInstance.version + 1
                    const newRequest = indexedDB.open(dbName, newVersion)
                    newRequest.onupgradeneeded = (event) => {
                        const db = (event.target as IDBOpenDBRequest).result
                        db.createObjectStore(storeName)
                    }
                    newRequest.onsuccess = () => {
                        dbInstance = newRequest.result
                        resolve(dbInstance)
                    }
                    newRequest.onerror = reject
                }
                else {
                    resolve(dbInstance)
                }
            }
            request.onupgradeneeded = (event) => {
                const db = (event.target as IDBOpenDBRequest).result
                if (!db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName)
                }
            }
        })
    }
    async function withStore<T>(mode: IDBTransactionMode, callback: (store: IDBObjectStore) => IDBRequest<T>): Promise<T> {
        const db = await setup()
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(storeName, mode)
            const store = transaction.objectStore(storeName)
            const request = callback(store)
            request.onerror = reject
            request.onsuccess = () => resolve(request.result)
        })
    }

    async function get(key: string): Promise<any> {
        return withStore('readonly', store => store.get(key))
    }

    async function set(key: string, value: any): Promise<void> {
        await withStore('readwrite', store => store.put(value, key))
    }

    async function remove(key: string): Promise<void> {
        await withStore('readwrite', store => store.delete(key))
    }

    return { get, set, remove }
}
